import React from "react";
import Layout from "../components/layout";
import { triggerTealiumEvent } from "../utils/tealiumUtils";
import { useLocation } from "@reach/router";

import {
  SLA_DATE,
  SLA_RESIDENTAL_EMPLOYED,
  SLA_RESIDENTAL_SELF_EMPLOYED,
  SLA_BUY_TO_LET,
  SLA_ASSESSING_SUPPORTING_DOCUMENTS,
  SLA_RESPONDING_BROKER_MESSAGES,
  SLA_REVIEWING_APPLICATION_AMENDMENT_FORMS,
  SLA_ANSWERING_TIME,
  SLA_MONTH,
} from "../data/sla.data";

import "../styles/app.scss";

export default function OurLatestServiceLevels() {
  const location = useLocation();
  if (typeof window !== "undefined" && window.utag) {
    triggerTealiumEvent({
      tealiumEvent: "view",
      pageUrl: location.href,
      pageType: "product information",
      pageSubCategory: "our latest service levels",
      rawDataLayer: "10451v68",
    });
  }

  const handleClick = ({ eventContent, eventAction, rawDataLayer }) => {
    if (typeof window !== "undefined" && window.utag) {
      triggerTealiumEvent({
        tealiumEvent: "link",
        pageUrl: location.href,
        pageName:"our latest service levels",
        eventCategory: "content",
        eventAction: eventAction,
        eventContent: eventContent,
        pageType: "product information",
        pageSubCategory: "our latest service levels",
        rawDataLayer: rawDataLayer,
      });
    }
  };

  return (
    <Layout
      title="Our latest service levels"
      metaDescription="We have recently been experiencing high volumes of applications and amendments, which is impacting our service levels. We are doing everything we can to improve our current position."
    >
      <section className="page-section row section">
        <div className="col-xs-12">
          <h1 className="page-title">Our latest service levels</h1>
          <h3 className="w-fst2">{SLA_DATE.value}</h3>
          <p>
            Please check the case tracking facility <strong>and</strong> the messages section on our{" "}
            <a
              href="https://platform.intermediaries.hsbc.co.uk/CSSO/LoginRequest?ReturnUrl=%2flogin%2f"
              target="_blank"
              onClick={() =>
                handleClick({
                  eventContent: "Broker Portal Link | Our Latest Service Levels: Broker Portal",
                  eventAction: "onsite",
                  rawDataLayer: "10451v71",
                })
              }
            >
              <strong>broker portal</strong>
            </a>{" "}
            and refer to the service levels below before contacting our broker support team for a case update.
          </p>
          <p>
            Please ensure your cases are <strong>fully packaged</strong> prior to submission so we can process your
            customer's application to offer as quickly as possible.
          </p>
          <h3 className="bold">New applications</h3>
          <table className="w-tbl-sla">
            <tbody>
              <tr className="section odd">
                <td className="text-center text-title">
                  <strong>Type</strong>
                </td>
                <td className="text-center text-title">
                  <strong>Average number of days' assessment</strong>
                </td>
              </tr>
              <tr className="even">
                <td>
                  <p>
                    <strong>{SLA_RESIDENTAL_EMPLOYED.title}</strong>
                  </p>
                </td>
                <td className="red text-center">
                  <p>
                    <strong>{SLA_RESIDENTAL_EMPLOYED.value}</strong>
                  </p>
                </td>
              </tr>
              <tr className="odd">
                <td>
                  <p>
                    <strong>{SLA_RESIDENTAL_SELF_EMPLOYED.title}</strong>
                  </p>
                </td>
                <td className="red text-center">
                  <p>
                    <strong>{SLA_RESIDENTAL_SELF_EMPLOYED.value}</strong>
                  </p>
                </td>
              </tr>
              <tr className="even">
                <td>
                  <p>
                    <strong>{SLA_BUY_TO_LET.title}</strong>
                  </p>
                </td>
                <td className="red text-center">
                  <p>
                    <strong>{SLA_BUY_TO_LET.value}</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h3 className="bold">Residential applications in progress</h3>

          <table className="w-tbl-sla">
            <tbody>
              <tr className="odd">
                <td>
                  <p>
                    Assessing <strong>supporting documents</strong>
                  </p>
                </td>
                <td className="red text-center">
                  <p>
                    <strong>{SLA_ASSESSING_SUPPORTING_DOCUMENTS.value}</strong>
                  </p>
                </td>
              </tr>
              <tr className="even">
                <td>
                  <p>
                    Responding to <strong>Broker messages</strong>
                  </p>
                </td>
                <td className="red text-center">
                  <p>
                    <strong>{SLA_RESPONDING_BROKER_MESSAGES.value}</strong>
                  </p>
                </td>
              </tr>
              <tr className="odd">
                <td>
                  <p>
                    Reviewing <strong>Application Amendment Forms</strong>
                  </p>
                </td>
                <td className="red text-center">
                  <p>
                    <strong>{SLA_REVIEWING_APPLICATION_AMENDMENT_FORMS.value}</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h3 className="bold">Broker Support Helpdesk</h3>
          <p>
            <i>Answering your calls (average for {SLA_MONTH.value})</i>
          </p>

          <table className="w-tbl-sla">
            <tbody>
              <tr className="odd">
                <td>
                  <p>
                    <strong>{SLA_ANSWERING_TIME.title}</strong>
                  </p>
                </td>
                <td className="red text-center">
                  <p>
                    <strong>{SLA_ANSWERING_TIME.value}</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h3 className="bold">Packaging your cases</h3>

          <p>
            For employed and self-employed customers, please refer to the{" "}
            <a className="underlined" target="_blank" 
              href="https://intermediaries.hsbc.co.uk/packaging-requirements"
              onClick={() =>
                handleClick({
                  eventContent: "Packaging requirements Link | Our Latest Service Levels: Packaging requirements",
                  eventAction: "onsite",
                  rawDataLayer: "10451v71",
                })
            }>
              Packaging requirements
            </a>{" "}
            page for requirements.
          </p>
        </div>
        {/* <div className="support-container col-xs-12 col-lg-4 col-lg-offset-1">
          <h1 className='page-title page-title--small text-center'>Resource</h1>
          <ResourcesWrapper />
        </div> */}
      </section>
    </Layout>
  );
}
